<template>
  <div>
    <base-page
      title="Farm resources"
      call-to-action-text="Add resource"
      call-to-action-permission="silo.resources.store"
      :breadcrumbs="breadcrumbs"
      @call-to-action="dialog = true"
    >
      <template #content>
        <resource-list
          @edit="edit"
        />
      </template>
    </base-page>

    <resource-form
      v-if="dialog"
      :dialog="dialog"
      :category="category"
      @close="close()"
    />
  </div>
</template>

<script>
export default {
  components: {
    'resource-list': () => import('@/views/silo/ResourceList.vue'),
    'resource-form': () => import('@/views/silo/ResourceForm.vue'),
  },

  data () {
    return {
      dialog: false,
      category: null,
      breadcrumbs: [
        { text: 'Farm resources', disabled: true, },
      ],
    }
  },

  methods: {
    close () {
      this.dialog = false
    },

    edit (category) {
      this.category = category
      this.dialog = true
    }
  }
} 
</script>